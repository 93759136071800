const relocate = [
  {
    name: "Yes",
    text: "Yes",
  },
  {
    name: "Just remote",
    text: "Just remote",
  },
  {
    name: "I already live in Germany",
    text: "Living in Germany",
  },
];

export default relocate;
