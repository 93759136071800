const visa = [
  {
    textDe: "Ja",
    name: "Yes",
    text: "Yes",
  },
  { textDe: "Nein", name: "No", text: "No" },
  {
    textDe: "EU Passport",
    text: "I have an EU-Passport",
    name: "I have an EU-Passport",
  },
];

export default visa;
