const educationalConfirmation = [
  {
    name: "Yes",
    text: "Yes",
  },
  {
    name: "No",
    text: "No",
  },
  {
    text: "I don't know",
    name: "I don't know",
  },
];

export default educationalConfirmation;
