import React from "react";
import JobOffer from "../../../components/JobOffer/JobOffer";

function CreateJobOfferPage() {
  return (
    <>
      <JobOffer type={"create"} />
    </>
  );
}

export default CreateJobOfferPage;
