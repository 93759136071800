const preferCity = [
  {
    name: "In a big city",
    text: "big",
  },
  {
    name: "In a middle-size city",
    text: "medium",
  },
  {
    name: "In a small town",
    text: "landscape",
  },
];

export default preferCity;
