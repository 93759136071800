const graduate = [
  {
    name: "Bachelor",
    text: "Bachelor",
  },
  {
    name: "Master",
    text: "Master",
  },
  {
    text: "PhD / Doctor",
    name: "PhD / Doctor",
  },
  {
    name: "Apprenticeship",
    text: "Apprenticeship",
  },
];

export default graduate;
